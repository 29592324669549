<template>
  <div>
    <b-row>
      <b-col
        md="12"
        sm="12"
        class="mb-2"
      >
        <b-card>

          <b-form

            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-row>
              <b-col md="4"> <b-form-group
                :label="$t('Başlangıç Tarihi')"
                label-for="name"
              >
                <b-form-datepicker
                  id="start_date"
                  v-model="filterData.start_date"
                  class="mb-1"
                />
              </b-form-group></b-col>
              <b-col md="4"> <b-form-group
                :label="$t('Bitiş Tarihi')"
                label-for="name"
              >
                <b-form-datepicker
                  id="end_date"
                  v-model="filterData.end_date"
                  class="mb-1"
                />
              </b-form-group></b-col>

            </b-row>

            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="fetchData"
              >
                Filtrele
              </b-button>
            </div>
          </b-form>

        </b-card>

      </b-col>
      <b-col md="5">
        <search-term-report
          ref="searchTermReport"
          :filter-data="filterData"
        />
      </b-col>
      <b-col md="7">
        <search-records
          ref="searchRecords"
          :filter-data="filterData"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BFormDatepicker,
  BButton,
  BForm,
  BFormGroup,
  BCard,
} from 'bootstrap-vue'
import store from '@/store'
import {
  onUnmounted,
} from '@vue/composition-api'
import searchRecords from './SearchRecords.vue'
import searchTermReport from './SearchTermReport.vue'
import reportsStoreModule from '../reportsStoreModule'

export default {
  components: {
    BCol,
    BRow,
    BFormDatepicker,
    BButton,
    BForm,
    BFormGroup,
    searchRecords,
    searchTermReport,
    BCard,
  },
  data() {
    return {
      options: {},
      filterData: {
        start_date: null,
        end_date: null,
      },
    }
  },
  created() {
    const today = this.$moment()
    const startDay = this.$moment()

    this.filterData.end_date = today.format()
    this.filterData.start_date = startDay.subtract(7, 'days').format()
  },
  beforeCreate() {

  },
  methods: {
    fetchData() {
      this.$refs.searchTermReport.refetchData()
      this.$refs.searchRecords.refetchData()
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-reports'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, reportsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
  },
}
</script>
