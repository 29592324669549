<template>

  <div>
    <b-row>

      <b-col>
        <!-- Filters -->

        <!-- Table Container Card -->
        <b-card
          no-body
          class="mb-0"
        >

          <div class="m-2">

            <!-- Table Top -->
            <b-row>

              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>{{ $t('Show') }}</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>{{ $t('Entries') }}</label>
              </b-col>

              <!-- Search -->
              <b-col
                cols="12"
                md="6"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    placeholder="Kelima Arayın..."
                  />

                </div>
              </b-col>
            </b-row>

          </div>

          <b-table
            ref="refOrderListTable"
            class="position-relative"
            :items="fetchOrders"
            responsive
            :fields="tableColumns"
            primary-key="id"
            show-empty
            empty-text="No matching records found"
          />
          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">{{ $t('Gösterilen') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('Entries') }}</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalOrders"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {
  ref, watch, computed,
} from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,

    vSelect,
  },
  props: {
    filterData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {

    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    filterData(val) {
      this.refetchData()
    },
  },
  created() {
    const today = this.$moment()
    this.filterData.start_date = today.subtract(7, 'days').format()
    this.filterData.end_date = today.format()
  },
  methods: {

  },
  setup(props) {
    const toast = useToast()
    // eslint-disable-next-line no-unused-vars
    const editProductId = ref(0)
    const isAddNewProductSidebarActive = ref(false)
    const isEditProductSidebarActive = ref(false)

    const perPageOptions = [10, 25, 50, 100]
    const perPage = ref(10)
    const searchQuery = ref('')
    const totalOrders = ref(0)
    const refOrderListTable = ref(null)
    const currentPage = ref(1)

    const tableColumns = [
      { key: 'search_term', label: 'Müşteri', sortable: false },
      { key: 'search_count', label: 'Aranma Sayısı', sortable: false },
    ]

    const refetchData = () => {
      refOrderListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery], () => {
      refetchData()
    })
    const dataMeta = computed(() => {
      const localItemsCount = refOrderListTable.value ? refOrderListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalOrders.value,
      }
    })
    const fetchOrders = (ctx, callback) => {
      store
        .dispatch('app-reports/fetchSearchTermReport', {
          q: searchQuery.value,
          perPage: perPage.value,
          page: currentPage.value,
          start_date: props.filterData.start_date,
          end_date: props.filterData.end_date,
        })
        .then(response => {
          const { data } = response.data.data

          const { total } = response.data.meta
          callback(data)
          totalOrders.value = total
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching product list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    return {

      // Sidebar
      isAddNewProductSidebarActive,
      isEditProductSidebarActive,
      tableColumns,
      // Filter
      avatarText,
      perPageOptions,
      perPage,
      dataMeta,
      fetchOrders,
      searchQuery,
      totalOrders,
      currentPage,
      refetchData,
      // UI
      refOrderListTable,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
